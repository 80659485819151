import React from 'react';
import styled from '@emotion/styled';
import { Container, ContainerSmall } from '~/utils/styles/utils';

const ImprintMain: React.FC = () => {
  return (
    <>
      <Container>
        <ContainerSmall>
          <ContainerSmallOverwritten>
            <h1>Stellenanzeigen</h1>
            <p>
              Wir suchen Trainer/-innen mit sportwissenschaftlicher
              Grundausbildung und/oder Physiotherapeuten/innen (gerne mit
              neurologischer Ausbildung) auf freiberuflicher oder 450 Euro
              Basis.
            </p>
            <p>
              Bitte melden unter:{' '}
              <a href="mailto:uez@neurozentrum-arabellapark.de">
                uez@neurozentrum-arabellapark.de
              </a>
            </p>
          </ContainerSmallOverwritten>
        </ContainerSmall>
      </Container>
    </>
  );
};

const ContainerSmallOverwritten = styled.div`
  p {
    font-size: var(--primary-font-size);
    line-height: 1.5;
  }
`;

export default ImprintMain;
